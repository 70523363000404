import {
  ARBITRUM_INFO,
  ARBITRUM_MULTICALL,
  ETHEREUM_GOERLI_CHAIN,
  ETHEREUM_INFO,
  ETHEREUM_SEPOLIA_CHAIN,
  ETHEREUM_SEPOLIA_INFO,
  ETHEREUM_SEPOLIA_MULTICALL,
  ARBITRUM_GOERLI_CHAIN,
  ARBITRUM_GOERLI_MULTICALL,
  ARBITRUM_GOERLI_INFO,
  ARBITRUM_GOERLI_GRAPHQL_URL,
  ETHEREUM_SEPOLIA_GRAPHQL_URL,
  ARBITRUM_GRAPHQL_URL,
  ARBITRUM_CHAIN
} from './contract';

export const CHAINID_NETWORK = {
  arbitrum: ARBITRUM_CHAIN,
  ethereum: ETHEREUM_GOERLI_CHAIN,
  sepolia: ETHEREUM_SEPOLIA_CHAIN,
  arbitrum_goerli:ARBITRUM_GOERLI_CHAIN
};

export const INFO_BASED_ON_CHAIN = {
  [CHAINID_NETWORK.arbitrum]: ARBITRUM_INFO,
  [CHAINID_NETWORK.sepolia]: ETHEREUM_SEPOLIA_INFO,
  [CHAINID_NETWORK.ethereum]: ETHEREUM_INFO,
  [CHAINID_NETWORK.arbitrum_goerli]: ARBITRUM_GOERLI_INFO
};

export const REACT_APP_MULTICALL = {
  [CHAINID_NETWORK.arbitrum]: ARBITRUM_MULTICALL,
  [CHAINID_NETWORK.sepolia]: ETHEREUM_SEPOLIA_MULTICALL,
  [CHAINID_NETWORK.arbitrum_goerli]: ARBITRUM_GOERLI_MULTICALL
};

export const REACT_APP_GRAPHQL_URL = {
  [CHAINID_NETWORK.arbitrum]: ARBITRUM_GRAPHQL_URL,
  [CHAINID_NETWORK.sepolia]: ETHEREUM_SEPOLIA_GRAPHQL_URL,
  [CHAINID_NETWORK.arbitrum_goerli]: ARBITRUM_GOERLI_GRAPHQL_URL,
  [CHAINID_NETWORK.ethereum]: ETHEREUM_SEPOLIA_GRAPHQL_URL,
};