import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TitleCardPageStyled } from 'assets/styled/CardPage';
import Page from 'components/Page';
import React from 'react';

const AboutPlatformPage = () => {
  const typoStyle = {
    fontFamili: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16,94px',
    marginTop: '15px',
  };
  return (
    <Page>
      <Box marginBottom={'62px'}>
        <TitleCardPageStyled
          sx={{
            fontFamily: 'Metropolis',
            fontWeight: '700',
            fontSize: '28px',
          }}
        >
          LIGHT HOUSE IN 5 SENTENCES
        </TitleCardPageStyled>
        <Box
          sx={{
            //   height: '272px',
            width: '50%',
            margin: 'auto',
          }}
        >
          <Typography sx={typoStyle}>
            We are building a platform for contemporary digital artists to release a new form of greeting
            card.
          </Typography>
          <Typography sx={typoStyle}>
            Because our artists make this art with code, no two cards purchased on our platform will look
            exactly the same.
          </Typography>
          <Typography sx={typoStyle}>
            Cards can now be so much more than a static image on paper, a “card” can be a whole mini game
            world with a message hidden somewhere inside.
          </Typography>
          <Typography sx={typoStyle}>
            We are going to make our cards NFTs so that whoever you send them to can own them forever. (You
            can still buy without crypto).
          </Typography>
          <Typography sx={typoStyle}>
            To do this we have designed a new NFT that encrypts your message in the card, making it viewable
            only to you.
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

export default AboutPlatformPage;
