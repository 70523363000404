import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from 'components/hook-form/TextField';
import {
  BACKEND_ACCOUNT,
  EXPECTED_CHAIN_ID,
  ORIGINAL_PROJECT_ID,
  SECRET_KEY,
  SNOWFLAKE_PROJECT_ID,
} from 'config';
import { Contract, ethers } from 'ethers';
import useWallet from 'hooks/Wallet/useWallet';
import useGiftingMutation from 'hooks/mutation/useGiftingMutation';
import useModalState from 'hooks/useModalState';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getCoreGenArt, getCoreLightHouse } from 'utils/contract/common';
import usePrice from 'utils/usePrice';
import { emailShemaFactory, messageSchemaFactory } from 'utils/yup-schema';
import { useContract, useProvider } from 'wagmi';
import * as yup from 'yup';
import MintModal from './MintModal';
import MintModalSuccess from './MintMondalSuccess';
import useCheckNetwork from 'hooks/useCheckNetwork';

const useStyles = makeStyles({
  inputStyle: {
    width: '100%',
    // backgroundColor: 'rgba(255,255,255,0.21)',
    borderRadius: '10px',
    minHeight: '58px',
    border: '1px solid #000',
    color: '#fff',
    paddingBottom: 4,

    '&.via': { width: '20%' },

    '& input': {
      height: '100%',
    },
  },
});

const inputLablePropsStyle = {
  fontSize: '16px',
  fontFamily: 'nunito-sans',
  color: 'rgba(255, 255, 255, 0.44)',
};

const inputLableSnowflakesPropsStyle = {
  fontSize: '16px',
  fontFamily: 'nunito-sans',
  color: 'black',
};

const inputPropsStyle = {
  color: '#fff',
  height: 'auto',
  fontFamily: 'nunito-sans',
  backgroundColor: 'rgba(255,255,255,0.21)',
};

const inputSnowflakesPropsStyle = {
  color: 'black',
  height: 'auto',
  fontFamily: 'nunito-sans',
  backgroundColor: 'rgba(255,255,255,0.21)',
};

function MintWithouReceiver({ tab, projectId }: any) {
  const classes = useStyles();
  const { isLoading: isLoadingGift, requestOTP } = useGiftingMutation();
  const { signer, account, chainId: connectedChainId } = useWallet();
  const provider = useProvider({ chainId: EXPECTED_CHAIN_ID });
  const { onOpen, onClose, isOpen } = useModalState();
  const modalSuccess = useModalState();
  const closeModalSuccess = modalSuccess.onClose;
  const openModalSuccess = modalSuccess.onOpen;
  const isOpenModalSuccess = modalSuccess.isOpen;

  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const LightHouseCoreContractInfo = getCoreLightHouse(chainId);
  const count = useCheckNetwork();
  const [loading, setLoading] = useState(false);
  const [OTPLink, setOTPLink] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [encryptMessage, setEncryptMessage] = useState<Uint8Array | undefined>();
  const [isAcceptedNotification, setIsAcceptedNotification] = useState<boolean>(false);

  const snowflakePage = projectId == SNOWFLAKE_PROJECT_ID;

  const LightHouseContract = useContract({
    address: LightHouseCoreContractInfo.address,
    abi: LightHouseCoreContractInfo.abi,
    signerOrProvider: signer,
  });

  const { ETHPrice, USDPrice, priceInETH } = usePrice(projectId, coreContractInfo?.address);

  const greetingCharactersLimit = 32;
  const messageCharactersLimit = projectId == SNOWFLAKE_PROJECT_ID ? 600 : 750;

  const schema = yup.object({
    message: messageSchemaFactory()
      .test('Have secrect key', `Message should not contain "${SECRET_KEY}"`, function (value) {
        return value.indexOf(SECRET_KEY || '') === -1;
      })
      .max(messageCharactersLimit, `Only ${messageCharactersLimit} characters are allowed.`),
    email: emailShemaFactory(),
    senderEmail: emailShemaFactory('Sender Email'),
    value: yup.number(),
    type: yup.string(),
    address: yup.string().when('type', (type, schema) => {
      if (type[0] === 'address')
        return schema
          .required('Wallet address is required.')
          .matches(/^0x[0-9,a-f,A-F]{40}$/, 'Wallet address is invalid.');
      return schema;
    }),
    shortGreeting:
      projectId === ORIGINAL_PROJECT_ID
        ? yup
            .string()
            .required('Greeting is required.')
            .test('Have secrect key', `Greeting should not contain "${SECRET_KEY}"`, function (value) {
              return value.indexOf(SECRET_KEY || '') === -1;
            })
            .max(greetingCharactersLimit, `Only ${greetingCharactersLimit} characters are allowed.`)
        : yup.string(),
    senderName: yup.string().required('Sender Full Name is required.'),
  });

  const methods = useForm({
    defaultValues: {
      message: '',
      email: '',
      address: '',
      shortGreeting: '',
      senderName: '',
      senderEmail: '',
    },
    resolver: yupResolver(schema),
  });

  const { getValues, reset } = methods;

  const handleZipCodeFromModal = useCallback(
    (zipCodeValue: string) => {
      setZipCode(zipCodeValue);
    },
    [zipCode]
  );

  const handleAcceptedNotification = useCallback(
    (isAccept: boolean) => {
      setIsAcceptedNotification(isAccept);
    },
    [isAcceptedNotification]
  );

  const handleVerifyOTPGifter = async () => {
    if (count >= 1 && +connectedChainId != EXPECTED_CHAIN_ID) {
      toast(`You are connected to wrong network. Please connect to ${provider.network.name}`, {
        type: 'error',
      });
      return;
    }

    const shortGreeting = getValues('shortGreeting');
    const message = getValues('message');
    const receiverEmail = getValues('email');
    const senderFullName = getValues('senderName');
    const senderEmail = getValues('senderEmail');
    const fullMessage = `${shortGreeting}${SECRET_KEY}${message}`;

    try {
      setLoading(true);
      if (account) {
        const encryptedMessageBytes = ethers.utils.toUtf8Bytes(fullMessage ? fullMessage : '0x');
        setEncryptMessage(encryptedMessageBytes);

        const result = await LightHouseContract?.mintToWithMessage(BACKEND_ACCOUNT, projectId, '0x', {
          value: priceInETH || 1,
        });
        const receipt = await result.wait();
        const getTokenId = receipt.events[2].args[2].toString();

        const requestGift = {
          tokenId: getTokenId,
          txHash: result.hash,
          fromBlock: receipt.blockNumber,
          receiverEmail,
          senderFullName,
          senderEmail,
          isAcceptedNotification,
          message: fullMessage,
          priceInUSD: USDPrice?.toString(),
          zipCode,
        };
        const requestGiftNFT = await requestOTP(requestGift);
        setOTPLink(requestGiftNFT?.data.otpLink);

        reset();
        onClose();
        openModalSuccess();

        toast(`Send gift successfully`, {
          type: 'success',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const isDisable = useMemo(() => isLoadingGift || loading, [isLoadingGift, loading]);

  const handleOpenMintModal = async (values: any) => {
    const sendMessage = `${getValues('shortGreeting')}${SECRET_KEY}{${getValues('message')}}`;
    const encryptedMessageBytes = ethers.utils.toUtf8Bytes(sendMessage ? sendMessage : '0x');
    setEncryptMessage(encryptedMessageBytes);
    onOpen();
  };

  const handleCloseMintModal = () => {
    onClose();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleOpenMintModal)}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '16px' }}>
            <TextField
              disabled={true}
              name="via"
              label="Via"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={`${classes.inputStyle} via`}
              variant="outlined"
            />

            <TextField
              fullWidth
              disabled={isDisable}
              name="email"
              label="Recipient Email"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={classes.inputStyle}
              variant="outlined"
              autoComplete="off"
            />
          </Box>
          <Box sx={{ marginBottom: '16px' }}>
            <TextField
              disabled={isDisable}
              label="Front greeting"
              name="shortGreeting"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={classes.inputStyle}
              sx={{
                display: projectId === ORIGINAL_PROJECT_ID ? '' : 'none',
              }}
              autoComplete="off"
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              marginBottom: '16px',
            }}
          >
            <TextField
              label="Message"
              name="message"
              multiline
              variant="outlined"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={classes.inputStyle}
              disabled={isDisable}
              fullWidth
              autoComplete="off"
              minRows={9}
              maxRows={9}
            />
          </Box>
          <Box sx={{ marginBottom: '16px' }}>
            <TextField
              label="Sender Full Name"
              name="senderName"
              variant="outlined"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={classes.inputStyle}
              disabled={isDisable}
              autoComplete="off"
              fullWidth
            />
          </Box>
          <Box sx={{ marginBottom: '16px' }}>
            <TextField
              label="Sender Email"
              name="senderEmail"
              variant="outlined"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              className={classes.inputStyle}
              disabled={isDisable}
              autoComplete="off"
              fullWidth
            />
          </Box>
          <Box marginTop={1}>
            <LoadingButton
              sx={{
                marginBottom: '16px',
                marginTop: '16px',
                border: '1px solid white',
                height: '60px',
                backgroundColor: '#000',
                borderRadius: '10px',
                color: '#fff',
                width: '100%',
                fontFamily: 'Metropolis',
                fontWeight: '600',
              }}
              type="submit"
              loading={USDPrice === 0}
            >
              PURCHASE ${ETHPrice} ETH (~${USDPrice})
            </LoadingButton>
            {isOpen && (
              <MintModal
                isLoading={isDisable}
                open={isOpen}
                onClose={handleCloseMintModal}
                onMint={handleVerifyOTPGifter}
                coreContractInfo={coreContractInfo}
                encryptMessage={encryptMessage}
                crossMintData={{
                  chainId,
                  // fromBlock: '',
                  receiverEmail: getValues('email'),
                  senderFullName: getValues('senderName'),
                  senderEmail: getValues('senderEmail'),
                  isAcceptedNotification: `${isAcceptedNotification}`,
                  // signature: '1',
                  priceInUSD: USDPrice?.toString(),
                  message: `${getValues('shortGreeting')}${SECRET_KEY}${getValues('message')}`,
                  priceInETH,
                  // backendWallet: BACKEND_ACCOUNT,
                  projectId,
                }}
                isGift
                projectId={projectId}
                totalPrice={ETHPrice.toString()}
                ETHPrice={ETHPrice}
                USDPrice={USDPrice}
                isAcceptNotification={handleAcceptedNotification}
                zipCodeValue={handleZipCodeFromModal}
                LightHouseContract={LightHouseContract && (LightHouseContract as Contract | undefined)}
                priceInETH={priceInETH}
              />
            )}
            {isOpenModalSuccess && (
              <MintModalSuccess
                onClose={closeModalSuccess}
                isLoading={false}
                open={isOpenModalSuccess}
                OTPLink={OTPLink}
              />
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

export default MintWithouReceiver;
