import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, Typography, styled } from '@mui/material';
import { useUserContext } from 'context/UserContext';
import useWalletDataMutation from 'hooks/mutation/useWalletDataMutation';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { emailShemaFactory } from 'utils/yup-schema';
import * as yup from 'yup';
import { TextField } from './hook-form/TextField';

type RegisterModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogContent = styled(Box)({
  padding: '16px 32px',
  width: 350,
});

const GetOTPButton = styled(LoadingButton)({
  marginTop: 8,
  textTransform: 'capitalize',
});

function RegisterModal({ isOpen, onClose }: RegisterModalPropsType) {
  const { refetch } = useUserContext();
  const schema = yup.object({
    email: emailShemaFactory(),
    otp: yup
      .string()
      .required('OTP is required.')
      .min(6, 'OTP must be 6 characters.')
      .max(6, 'OTP must be 6 characters.'),
  });

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    defaultValues: {
      email: '',
      otp: '',
    },
    resolver: yupResolver(schema),
  });

  const { trigger, getValues } = methods;

  const { isLoading, requestOTP, verifyOTP } = useWalletDataMutation();

  const handleGetOTP = async () => {
    const isValidEmail = await trigger('email');

    if (isValidEmail) {
      try {
        const email = getValues('email');
        await requestOTP(email);
        setActiveStep(1);
      } catch (error) {
        console.error({ 'Email OTP': error });
      }
    }
  };

  const handleVerifyRegister = async (values: any) => {
    try {
      await verifyOTP({ email: values.email, otp: values.otp });
      await refetch();
      toast('Register successfully.', { type: 'success' });
      onClose();
      setActiveStep(0);
      methods.reset();
    } catch (error) {
      console.error({ 'Email OTP verify': error });
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    methods.reset();

    onClose();
  };

  const steps = [
    {
      component: (
        <Box component="div">
          <TextField label="Email" name="email" fullWidth size="small" disabled={isLoading} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GetOTPButton loading={isLoading} onClick={handleGetOTP} size="small" variant="contained">
              Get OPT
            </GetOTPButton>
          </Box>
        </Box>
      ),
    },
    {
      component: (
        <Box component="div">
          <TextField
            name="otp"
            fullWidth
            size="small"
            label="OTP"
            value={methods.watch('otp')}
            disabled={isLoading}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GetOTPButton
              type="submit"
              loading={isLoading}
              sx={{ marginLeft: '4px' }}
              size="small"
              variant="contained"
            >
              Verify
            </GetOTPButton>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Typography component="h1" mb="20px" fontWeight="bold">
          {activeStep === 0 ? 'Register' : 'Please enter OTP'}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleVerifyRegister)}>
            <Box>{steps[activeStep].component}</Box>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default RegisterModal;
