import { Box, Typography } from '@mui/material';
import Page from 'components/Page';
import AlexImage from '../assets/images/Alex.jpg';
import BenImage from '../assets/images/Ben.jpg';
import HanaImage from '../assets/images/Hana.jpg';

const TEAMS = [
  {
    imageUrl: AlexImage,
    position: 'Co-Founder and CDO',
    fullname: 'ALEX TUVSHIN FINCH',
    description:
      "Alex is a programmer and artist, currently pursuing his Bachelor's degree in Computer Science and Acting at NYU. Alex is the head web designer for the Open Gate International Film Festival. He is also a professional artist, with works commissioned in multiple mediums ranging from oil paintings to p5.js scripts. Alex is passionate about art, creative coding, applied internet technology, and web3.",
  },
  {
    imageUrl: BenImage,
    position: 'Co-Founder and CEO',
    fullname: 'BEN TSENGEL FINCH',
    description:
      "Ben graduated magna cum laude from Princeton University with a degree in Electrical and Computer Engineering. During his time there, he founded the schools first student run sustainable investment fund which is now a leading student group there. Ben's studies were focused on energy transition technologies, blockchain, and art.",
  },
  {
    imageUrl: HanaImage,
    position: 'Co-Founder and COO',
    fullname: 'HANA WIDERMAN',
    description:
      'Hana is a writer who graduated magna cum laude from Princeton University with a degree in English. In the fall, she will be attending the highly competitive Cornell MFA program in creative writing. Her work is published in numerous leading poetry journals and has been recognized for multiple awards and accolades. Hana has worked for the Princeton University Art Museum.',
  },
];

function AboutPage() {
  return (
    <>
      <Page>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '50px', marginBottom: '20px' }}>
            <Box sx={{ flexBasis: '385px' }}>
              <Typography
                sx={{
                  fontSize: { xs: '18px', md: '20px', fontWeight: '700' },
                  color: 'white',
                  fontFamily: 'metropolis',
                  // Additional styling if needed
                }}
              >
                OUR TEAM
              </Typography>
            </Box>
            {/* Empty boxes to keep the layout consistent */}
            <Box sx={{ flexBasis: '385px' }}></Box>
            <Box sx={{ flexBasis: '385px' }}></Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '50px' }}>
            {TEAMS.map((member) => {
              return (
                <Box key={member.fullname} sx={{ flexBasis: '385px', justifyContent: 'flex-start' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                    {' '}
                    {/* Centering only the image */}
                    <img
                      style={{ width: '240px', height: '240px', borderRadius: '50%' }}
                      alt={member.fullname}
                      src={member.imageUrl}
                    />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: '15px',
                      fontSize: '16px',
                      fontStyle: 'italic',
                      fontFamily: 'Nunito Sans',
                    }}
                  >
                    {member.position}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      marginTop: '5px',
                      fontFamily: 'Metropolis',
                      fontStyle: 'bold',
                    }}
                  >
                    {member.fullname}
                  </Typography>
                  <Typography sx={{ marginTop: '5px', fontSize: '14px', fontFamily: 'Inter' }}>
                    {member.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Page>
    </>
  );
}

export default AboutPage;
