import { Contract } from 'ethers';
import { ContractInfo } from 'types/common';

export default async function getUserMessageEncrypted(
  tokenId: string,
  account: `0x${string}`,
  signerOrProvider: any,
  coreContractInfo: ContractInfo
) {
  const CoreContract = new Contract(coreContractInfo.address, coreContractInfo.abi, signerOrProvider);

  const messageEncrypted = await CoreContract.userEncryptedData(tokenId, account);

  return messageEncrypted;
}
