import { VerifyOTPSchema } from './../types/walletData';
import axiosClient from 'api/axiosClient';

const baseWalletDataUrl = 'wallet-data';
const baseRegisterUrl = `${baseWalletDataUrl}/register`;

const walletDataAPI = {
  getUserData(emailOrWalletAddress: string | `0x${string}`) {
    const url = `${baseWalletDataUrl}/user`;
    return axiosClient.get(url, {
      params: {
        emailOrWalletAddress,
      },
    });
  },

  requestOTP(email: string) {
    const url = `${baseRegisterUrl}/requestOTP`;

    return axiosClient.post(url, {
      email,
    });
  },

  verifyOTP({ email, walletAddress, signature, encryptPublicKey, otp }: VerifyOTPSchema) {
    const url = `${baseRegisterUrl}/verifyOTP`;

    return axiosClient.post(url, {
      email,
      walletAddress,
      signature,
      publicKey: encryptPublicKey,
      otp,
    });
  },
};


export default walletDataAPI;
