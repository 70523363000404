import { Box, Typography } from '@mui/material';

function InfoMintProjectId2({ tab }: { tab: number }) {
  return (
    <Box
      sx={{
        marginX: '12px',
        border: '1px solid #fff',
        height: tab == 1 ? '645px' : '425px',
        fontFamily: 'Inter',
        fontSize: '14px',
        maxWidth: '640px',
        minWidth: '540px',
      }}
    >
      <Box
        sx={{
          textAlign: 'right',
          paddingX: '14px',
          paddingTop: '26px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '16.94px',
          }}
        >
          1. Choose whether to send as a gift or collect it for yourself.<br></br>
        </Typography>
        {tab == 1 && (
          <Typography
            sx={{
              marginTop: '40px',
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '16.94px',
            }}
          >
            2. Enter the recipient email.
          </Typography>
        )}
        <Typography
          sx={{
            marginTop: '55px',
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '16.94px',
          }}
        >
          {tab == 1 ? 3 : 2}. Enter your message. In the demo above<br></br>
          this message begins with “Dear Ben”
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '16.94px',
              color: '#929292',
            }}
          >
            This will only be visible to the recipient.<br></br>
            Learn about our encryption tech tech&nbsp;
            <span
              style={{
                textDecoration: 'underline',
                fontFamily: 'Inter',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '16.94px',
                color: '#929292',
              }}
            >
              here
            </span>
            .
          </Typography>
        </Typography>
        <Typography
          sx={{
            marginTop: '60px',
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16.94px',
          }}
        >
          A unique live art piece will be created when you press purchase.
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '16.94px',
              color: '#929292',
            }}
          >
            This art piece will be publicly viewable on the blockchain.
          </Typography>
        </Typography>
        {tab == 1 && (
          <>
            <Typography
              sx={{
                marginTop: '100px',
                fontFamily: 'Inter',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '16.94px',
              }}
            >
              4. Enter your name, this will be provided to the recipient.
            </Typography>
            <Typography
              sx={{
                marginTop: '50px',
                fontFamily: 'Inter',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '16.94px',
              }}
            >
              5. Enter your email.
            </Typography>
          </>
        )}

        <Typography
          sx={{
            marginTop: tab == 1 ? '70px' : '120px',
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '16.94px',
          }}
        >
          {tab == 1 ? (
            <>
              6. Purchase with ETH or credit card.
            </>
          ) : (
            <> 3. Purchase with ETH.</>
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default InfoMintProjectId2;
