import Modal from '@mui/material/Modal';
// @ts-ignore
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles: () => void = makeStyles((theme: any) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '1px solid rgb(48, 48, 48)',
    position: 'relative',
    backgroundColor: '#000',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backdropFilter: 'blur(5px)',
    color: 'rgba(255, 255, 255, 0.8)',
  },
}));

const MintModalSuccess = ({
  open,
  onClose,
  isLoading,
  OTPLink
}: {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  OTPLink?: string;
}) => {
  const classes: any = useStyles();

  const profileLink = '../../profile';

  const typographyStyle = {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21,78px',
    color: '#BFBFBF',
    marginBottom: '15px',
  };

  return (
    <Box>
      {open && <Box className={classes.overlay} onClick={onClose} />}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
      >
        <Box
          className={classes.paper}
          sx={{ width: '932px', height: OTPLink != profileLink ? '590px' : '350px' }}
        >
          <Box
            paddingLeft={'56px'}
            sx={{
              borderBottom: '1px solid rgb(48, 48, 48)',
              alignItems: 'left',
            }}
          >
            <Box>
              <Typography
                id="modal-title"
                sx={{
                  fontFamily: 'Metropolis',
                  fontSize: '18px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  height: 'full',
                  paddingY: '20px',
                }}
              >
                CHECKOUT
              </Typography>
            </Box>
          </Box>
          <Box marginX={'56px'}>
            <Box marginTop={'24px'} marginBottom={'18px'}>
              <Typography
                sx={{
                  fontFamily: 'Metropolis',
                  fontWeight: 700,
                  fontSize: '18px',
                  color: '#32B24E',
                }}
              >
                PURCHASE SUCCESS
              </Typography>
            </Box>
            <Box>
              <Typography sx={typographyStyle}>
                Congratulations, you have successfully purchased a Light House Greeting card!
              </Typography>
              {OTPLink != profileLink && (
                <Typography sx={typographyStyle}>
                  If you purchased this as a gift, we have emailed the recipient with a claim link which will
                  be valid for a month. Sometimes these e-mails can get filtered, so we recommend sharing your
                  gift directly them.
                  {OTPLink ? (
                    <span style={typographyStyle}> You can do that by sending them this link:</span>
                  ) : (
                    <span style={typographyStyle}>
                      {' '}
                      You can do that by getting the link from the email you should receive from us.
                    </span>
                  )}
                </Typography>
              )}
              <Typography sx={typographyStyle}>
                {OTPLink ? (
                  <Link style={{ wordBreak: 'break-all' }} to={`${OTPLink}`}>
                    {OTPLink != profileLink ? OTPLink : 'Go to Profile page to see your card.'}
                  </Link>
                ) : (
                  <></>
                )}
              </Typography>
              {OTPLink != profileLink && (
                <Typography sx={typographyStyle}>
                  Please note that anyone with this link can view and claim your greeting card so only share
                  it with the intended recipient. If you would like to take a look at the card, you are
                  welcome to click the link to view. Unless you claim it yourself, this action will not affect
                  the recipient’s ability to claim the card. If you have any issues please contact us at
                  contact@lighthousecards.io.
                </Typography>
              )}
              <Typography sx={typographyStyle} marginTop={'40px'}>
                Cheers,
              </Typography>
              <Typography sx={typographyStyle}>LIGHT HOUSE</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MintModalSuccess;
