import Mint from 'components/MintPage';
import Page from 'components/Page';
import useWallet from 'hooks/Wallet/useWallet';

const MintPage = () => {
  const { connected } = useWallet();
  return <Page>{true && <Mint projectId={'0'}/>}</Page>;
};

export default MintPage;
