// @ts-nocheck
import { ethers } from 'ethers';
import { metamaskProvider } from './common';

export default async function signMessage(message?: string) {
  if (typeof metamaskProvider === 'undefined') return '';

  const provider = new ethers.providers.Web3Provider(metamaskProvider);
  const signer = provider.getSigner();

  const signature = await signer.signMessage(message);

  return signature;
}
