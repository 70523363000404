import { CoreContractV3ABI, MintFilterV2ABI, MintSetPriceV5ABI, MinterSetPriceERC20V5ABI, LightHouseCore } from 'abi';
import { INFO_BASED_ON_CHAIN } from 'constants/networkChainId';

export const getCoreGenArt = (networkId: string) => ({
  address: INFO_BASED_ON_CHAIN[networkId]?.GEN_ART_721_CORE_V3_ENGINE_LOGIC,
  abi: CoreContractV3ABI,
});

export const getCoreLightHouse = (networkId: string) => ({
  address: INFO_BASED_ON_CHAIN[networkId]?.LIGHT_HOUSE_CORE,
  abi: LightHouseCore,
});

export const getMinterFilterV2 = (networkId: string) => ({
  address: INFO_BASED_ON_CHAIN[networkId]?.MINT_CONTRACT_ADDRESS,
  abi: MintFilterV2ABI,
});

export const getMinterSetPriceV5 = (networkId: string) => ({
  address: INFO_BASED_ON_CHAIN[networkId]?.MINTER_SET_PRICE_V5_ADDRESS,
  abi: MintSetPriceV5ABI,
});

export const getMinterSetPriceERC20V5 = (networkId: string) => ({
  address: INFO_BASED_ON_CHAIN[networkId]?.MINTER_SET_PRICE_ERC20_V5_ADDRESS,
  abi: MinterSetPriceERC20V5ABI,
});

export const getAllInfoAboutChain = (networkId: string) => INFO_BASED_ON_CHAIN[networkId];

export const metamaskProvider =
  window.ethereum?.providers?.find((provider) => provider.isMetaMask) || window.ethereum;
