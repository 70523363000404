import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MintModal from 'components/MintPage/MintModal';
import MintModalSuccess from 'components/MintPage/MintMondalSuccess';
import { TextField } from 'components/hook-form/TextField';
import { EXPECTED_CHAIN_ID, ORIGINAL_PROJECT_ID, SECRET_KEY, SNOWFLAKE_PROJECT_ID } from 'config';
import { ethers } from 'ethers';
import useWallet from 'hooks/Wallet/useWallet';
import useGiftingMutation from 'hooks/mutation/useGiftingMutation';
import useModalState from 'hooks/useModalState';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getCoreGenArt, getCoreLightHouse } from 'utils/contract/common';
import getEncryptPublicKey from 'utils/contract/getEncryptPulicKey';
import { encryptData } from 'utils/encryptData';
import usePrice from 'utils/usePrice';
import { messageSchemaFactory } from 'utils/yup-schema';
import { useContract, useProvider } from 'wagmi';
import * as yup from 'yup';

const useStyles = makeStyles({
  inputStyle: {
    width: '100%',
    // backgroundColor: 'rgba(255,255,255,0.21)',
    borderRadius: '10px',
    minHeight: '58px',
    border: '1px solid #000',
    color: '#fff',
    paddingBottom: 4,

    '& input': {
      height: '100%',
    },
  },
});

const inputLablePropsStyle = {
  fontSize: '16px',
  fontFamily: 'nunito-sans',
  color: 'rgba(255, 255, 255, 0.44)',
};

const inputLableSnowflakesPropsStyle = {
  fontSize: '16px',
  fontFamily: 'nunito-sans',
  color: 'black',
};

const inputPropsStyle = {
  color: '#fff',
  height: 'auto',
  fontFamily: 'nunito-sans',
  backgroundColor: 'rgba(255,255,255,0.21)',
};

const inputSnowflakesPropsStyle = {
  color: 'black',
  height: 'auto',
  fontFamily: 'nunito-sans',
  backgroundColor: 'rgba(255,255,255,0.21)',
};
interface Props {
  coreContractAddress: string;
  projectId: string;
}

const MinterSetPriceV5Interface = ({ coreContractAddress, projectId }: Props) => {
  const classes = useStyles();
  const { collect } = useGiftingMutation();
  const { signer, account, chainId: connectedChainId } = useWallet();
  const provider = useProvider({ chainId: EXPECTED_CHAIN_ID });
  const { onOpen, onClose, isOpen, onToggle } = useModalState();
  const modalSuccess = useModalState();
  const closeModalSuccess = modalSuccess.onClose;
  const openModalSuccess = modalSuccess.onOpen;
  const isOpenModalSuccess = modalSuccess.isOpen;

  const [isLoading, setIsLoading] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [encryptMessage, setEncryptMessage] = useState<Uint8Array | undefined>();
  const [isAcceptedNotification, setIsAcceptedNotification] = useState<boolean>(false);

  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const LightHouseCoreContractInfo = getCoreLightHouse(chainId);

  const snowflakePage = projectId == SNOWFLAKE_PROJECT_ID;

  const LightHouseContract = useContract({
    address: LightHouseCoreContractInfo.address,
    abi: LightHouseCoreContractInfo.abi,
    signerOrProvider: signer,
  });

  const { ETHPrice, USDPrice, priceInETH } = usePrice(projectId, coreContractInfo?.address);

  const messageCharactersLimit = projectId == SNOWFLAKE_PROJECT_ID ? 600 : 750;

  const schema = yup.object({
    message: messageSchemaFactory()
      .required('Message is required.')
      .test('Have secrect key', `Greeting should not contain "${SECRET_KEY}"`, function (value) {
        return value.indexOf(SECRET_KEY || '') === -1;
      })
      .max(messageCharactersLimit, `Only ${messageCharactersLimit} characters are allowed.`),
    greeting:
      projectId === ORIGINAL_PROJECT_ID
        ? yup
            .string()
            .required('Greeting is required.')
            .test('Have secrect key', `Greeting should not contain "${SECRET_KEY}"`, function (value) {
              return value.indexOf(SECRET_KEY || '') === -1;
            })
        : yup.string(),
  });

  const methods = useForm({
    defaultValues: {
      message: '',
      greeting: '',
    },
    resolver: yupResolver(schema),
  });
  const { getValues, reset } = methods;

  const handleZipCodeFromModal = useCallback(
    (zipCodeValue: string) => {
      setZipCode(zipCodeValue);
    },
    [zipCode]
  );

  const handleAcceptedNotification = useCallback(
    (isAccept: boolean) => {
      setIsAcceptedNotification(isAccept);
    },
    [isAcceptedNotification]
  );

  const handleSubmit = async () => {
    const message = getValues('message');

    if (connectedChainId != chainId) {
      toast(`You are connected to wrong network. Please connect to ${provider.network.name}`, {
        type: 'error',
      });
      return;
    }

    try {
      setIsLoading(true);

      if (account) {
        const encryptPublicKey = await getEncryptPublicKey(account);

        const sendMessage = `${getValues('greeting')}${SECRET_KEY}${getValues('message')}`;
        const encryptMessage = encryptData(sendMessage, encryptPublicKey);
        const encryptedMessageBytes = ethers.utils.toUtf8Bytes(sendMessage === '' ? '' : encryptMessage);

        const result = await LightHouseContract?.mintWithMessage(projectId, encryptedMessageBytes, {
          value: priceInETH || 1,
        });
        const receipt = await result.wait();
        const tokenId = receipt.events[2].args[2].toString();

        const requestGift = {
          tokenId,
          txHash: result.hash,
          fromBlock: receipt.blockNumber,
          receiverWalletAddress: account,
          isAcceptedNotification,
          message,
          priceInUSD: USDPrice?.toString(),
          zipCode,
        };
        await collect(requestGift);

        reset();
        onClose();
        openModalSuccess();

        toast(`Collect successfully.`, {
          type: 'success',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseMintModal = () => {
    onClose();
  };

  async function mint() {
    if (account) {
    }
    onOpen();
  }

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(mint)}>
          <Box sx={{ marginBottom: '16px' }}>
            <TextField
              disabled={isLoading}
              label="Front greeting"
              name="greeting"
              InputLabelProps={{
                style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
              }}
              InputProps={{
                style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
              }}
              sx={{
                display: projectId === ORIGINAL_PROJECT_ID ? '' : 'none',
              }}
              className={classes.inputStyle}
              autoComplete="off"
              variant="outlined"
            />
          </Box>
          <TextField
            label="Message"
            name="message"
            multiline
            variant="outlined"
            InputLabelProps={{
              style: snowflakePage ? inputLableSnowflakesPropsStyle : inputLablePropsStyle,
            }}
            InputProps={{
              style: snowflakePage ? inputSnowflakesPropsStyle : inputPropsStyle,
            }}
            className={classes.inputStyle}
            disabled={isLoading}
            fullWidth
            autoComplete="off"
            minRows={9}
            maxRows={9}
          />

          <Box marginTop={1}>
            {isOpen && (
              <MintModal
                open={isOpen}
                onClose={handleCloseMintModal}
                onMint={handleSubmit}
                coreContractInfo={coreContractInfo}
                encryptMessage={encryptMessage}
                isLoading={isLoading}
                projectId={projectId}
                // crossMintData={{
                //   chainId: `${EXPECTED_CHAIN_ID}`,
                //   receiverEmail: getValues('email'),
                //   senderFullName: getValues('senderName'),
                //   isAcceptedNotification: isAcceptedNotification,
                //   signature: '1',
                // }}
                totalPrice={ETHPrice.toString()}
                ETHPrice={ETHPrice}
                USDPrice={USDPrice}
                isAcceptNotification={handleAcceptedNotification}
                zipCodeValue={handleZipCodeFromModal}
              />
            )}
            <LoadingButton
              type="submit"
              sx={{
                marginBottom: '16px',
                marginTop: '16px',
                border: '1px solid white',
                height: '60px',
                backgroundColor: '#000',
                borderRadius: '10px',
                color: '#fff',
                width: '100%',
                fontFamily: 'Metropolis',
                fontWeight: '600',
              }}
              loading={USDPrice === 0}
            >
              PURCHASE ${ETHPrice} ETH (~$${USDPrice})
            </LoadingButton>
          </Box>
        </form>
      </FormProvider>
      <MintModalSuccess
        onClose={closeModalSuccess}
        isLoading={false}
        open={isOpenModalSuccess}
        OTPLink={`../../profile`}
      />
    </Box>
  );
};

export default MinterSetPriceV5Interface;
