import { gql, useQuery as useClientQuery } from '@apollo/client';
import { EXPECTED_CHAIN_ID } from 'config';
import { ethers } from 'ethers';
import { getCoreGenArt } from 'utils/contract/common';

type ProjectType = {
  name?: string;
  description?: string;
};

export const useFetchProject = (projectId: string) => {
  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const coreContract = coreContractInfo?.address?.toLowerCase();

  const { data, refetch, loading, error } = useClientQuery(
    gql`
      query ProjectQuery($coreContract: String!, $projectId: String!) {
        contract(id: $coreContract) {
          projects(where: { projectId: $projectId }) {
            name
            description
          }
        }
      }
    `,
    {
      variables: { coreContract, projectId },
    }
  );

  let project: ProjectType = {};

  if (error) {
    return {
      project,
      refetch: () => {},
      loading: false,
      error,
    };
  }

  project = data?.contract?.projects?.[0];
  return { project, refetch, loading, error };
};
