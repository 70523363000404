import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ConnectProfileButtonCustom from 'components/ConnectProfileButtonCustom';
import Page from 'components/Page';
import ListNFT from 'components/pages/Profile/ListNFT';
import useWallet from 'hooks/Wallet/useWallet';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  titlePage: {
    '&.title': {
      fontSize: 24,
      fontFamily: 'Metropolis',
      textTransform: 'uppercase',
      fontWeight: 700,
    },
  },
});

function ProfilePage() {
  const classes = useStyles();
  const { account } = useWallet();

  useEffect(() => {
    if (!account) {
      toast(`Please connect your wallet`, {
        type: 'warning',
      });
    }
  }, [account]);

  return (
    <Page>
      <Typography className={`${classes.titlePage} title`}>Profile</Typography>
      {account ? (
        <>
          <ListNFT />
        </>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '20%' }}>
          <ConnectProfileButtonCustom />
        </Box>
      )}
    </Page>
  );
}

export default ProfilePage;
