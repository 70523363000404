import Page from 'components/Page';
import ProjectDetails from 'components/ProjectDetails';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const ProjectPage = () => {
  const [snowflakePage, setSnowflakePage] = useState(false);
  const { projectId } = useParams();
  return (
    <Page snowflakePage={snowflakePage}>
      {projectId && (
        <ProjectDetails id={projectId} setSnowflakePage={setSnowflakePage} snowflakePage={snowflakePage} />
      )}
    </Page>
  );
};

export default ProjectPage;
