import { useCallback } from 'react';
import giftingAPI from 'services/gifting';
import {
  ChangeReceiverEmailSchema,
  CollectSchema,
  GetAccessTokenSchema,
  RegisterAndReceiveGiftSchema,
  RequestOTPGiftingSchema,
  StripeData,
  VerifyOTPGiferSchema,
  VerifyOTPReceiverSchema,
} from 'types/gifting';

function useGiftingApiCall() {
  const collectCall = useCallback(async (schema: CollectSchema) => await giftingAPI.collect(schema), []);

  const requestOTPCall = useCallback(
    async ({
      chainId,
      tokenId,
      txHash,
      fromBlock,
      receiverEmail,
      senderFullName,
      isAcceptedNotification,
      message,
      priceInUSD,
      senderEmail,
      zipCode,
    }: RequestOTPGiftingSchema) =>
      await giftingAPI.requestOTP({
        chainId,
        tokenId,
        txHash,
        fromBlock,
        receiverEmail,
        senderFullName,
        isAcceptedNotification,
        message,
        priceInUSD,
        senderEmail,
        zipCode,
      }),
    []
  );

  const verifyOTPGifterCall = useCallback(
    async ({ email, otp }: VerifyOTPGiferSchema) => await giftingAPI.verifyOTPGifter({ email, otp }),
    []
  );

  const verifyOTPReceiverCall = useCallback(
    async ({ walletAddress, accessToken }: VerifyOTPReceiverSchema) =>
      await giftingAPI.verifyOTPReceiver({ walletAddress, accessToken }),
    []
  );

  const registerAndReceiverCall = useCallback(
    async ({ walletAddress, signature, encryptPublicKey, accessToken }: RegisterAndReceiveGiftSchema) =>
      await giftingAPI.registerAndReceiveGift({
        walletAddress,
        signature,
        encryptPublicKey,
        accessToken,
      }),
    []
  );

  const changeReceiverEmailCall = useCallback(
    async ({
      chainId,
      addressGifter,
      tokenId,
      signature,
      receiverEmail,
      message,
    }: ChangeReceiverEmailSchema) =>
      await giftingAPI.changeReceiverEmail({
        chainId,
        addressGifter,
        tokenId,
        signature,
        receiverEmail,
        message,
      }),
    []
  );

  const getAccessTokenCall = useCallback(async ({ passcode, emailReceive }: GetAccessTokenSchema) => {
    return await giftingAPI.getAccessToken({
      passcode,
      emailReceive,
    });
  }, []);

  const getGiftingDataCall = useCallback(async (accessToken: any) => {
    return await giftingAPI.getGiftingData(accessToken);
  }, []);

  const getStripeCall = useCallback(async (data: StripeData) => {
    return await giftingAPI.getStripe(data);
  }, []);

  return {
    collectCall,
    requestOTPCall,
    verifyOTPGifterCall,
    verifyOTPReceiverCall,
    registerAndReceiverCall,
    changeReceiverEmailCall,
    getAccessTokenCall,
    getGiftingDataCall,
    getStripeCall,
  };
}

export default useGiftingApiCall;
