import { gql, useQuery as useClientQuery } from '@apollo/client';
import { EXPECTED_CHAIN_ID } from 'config';
import { ethers } from 'ethers';
import useWallet from 'hooks/Wallet/useWallet';
import { getCoreGenArt } from 'utils/contract/common';

export const useFetchArtList = () => {
  const { account } = useWallet();
  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const GET_ART_LIST = gql`
    query ArtListQuery($account: String!, $coreContract: String!) {
      contracts(where: { id: $coreContract }) {
        projects {
          projectId
          description
          name
          tokens(where: { owner_: { id: $account } }) {
            tokenId
          }
        }
      }
    }
  `;

  const { data, refetch, loading, error } = useClientQuery(GET_ART_LIST, {
    variables: { account: account?.toLowerCase(), coreContract: coreContractInfo?.address?.toLowerCase() },
  });

  if (error) {
    return {
      artList: [],
      refetch: () => {},
      loading: false,
      error,
    };
  }

  const artList: any[] =
    data?.contracts?.length !== 0
      ? data?.contracts[0]?.projects?.map((project: any) => {
          return {
            projectId: project?.projectId,
            tokenId: project?.tokens.map((token: any) => token.tokenId),
            description: project?.description,
            name: project?.name,
          };
        })
      : [];

  return { artList, refetch, loading, error };
};
