import axios from 'axios';
import { EXPECTED_CHAIN_ID } from 'config';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';
import { getMinterSetPriceV5 } from './contract/common';
interface IUsePrice {
  ETHPrice: number;
  USDPrice: number;
  priceInETH: string;
}
const usePrice = (projectId: string, coreContractAddress: string): IUsePrice => {
  const [ETHPrice, setETHPrice] = useState(0);
  const [USDPrice, setUSDPrice] = useState(0);
  const [priceInETH, setPriceInETH] = useState('');
  const expectedChain = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const minterSetPriceV5Info = getMinterSetPriceV5(expectedChain);

  const { data, isError, isLoading } = useContractRead({
    address: (minterSetPriceV5Info.address as `0x${string}`) || '0x7ceCe0d27730A36953aBd8f64AA1231Dacfa6c9E',
    abi: minterSetPriceV5Info.abi,
    functionName: 'getPriceInfo',
    args: [projectId, coreContractAddress || '0x45aF9A18239F5b354d1C85aF0e014D1c3013D89A'],
    watch: true,
    chainId: EXPECTED_CHAIN_ID,
    onSuccess(data: any) {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data) {
          const exchangeEthRateCoinbase = await axios.get(
            'https://api.coinbase.com/v2/exchange-rates?currency=ETH'
          );

          const weiAmount = data?.tokenPriceInWei;
          const etherAmount = weiAmount / Math.pow(10, 18);
          const usdAmount = Number((etherAmount * exchangeEthRateCoinbase.data.data.rates.USD).toFixed(2));

          setETHPrice(etherAmount);
          setPriceInETH(weiAmount.toString());
          setUSDPrice(usdAmount);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectId, coreContractAddress, data]);

  return { ETHPrice, USDPrice, priceInETH };
};

export default usePrice;
