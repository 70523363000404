import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import Loading from 'components/Loading';
import { EXPECTED_CHAIN_ID, ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';
import { ethers } from 'ethers';
import useWallet from 'hooks/Wallet/useWallet';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TokenData, TokenDataForSentiments } from 'types/common';
import isTokenIdExist from 'utils/contract/checkTokenIdExist';
import { getCoreGenArt, getCoreLightHouse } from 'utils/contract/common';
import getUserMessageEncrypted from 'utils/contract/getUserMessageEncrypted';
import useProjectExternalById from 'utils/contract/useProjectExternalById';
import { decrypt } from 'utils/encryptData';
import { getProjectId, getTokenHash } from 'utils/generateTokenData';
import { useProvider } from 'wagmi';
import ConnectProfileButtonCustom from './ConnectProfileButtonCustom';
import SketchArtOriginal from './SketchArtOriginal';
import SketchArtSentiments from './SketchArtSentiments';
import SketchArtSnowFlake from './SketchArtSnowFlake';

interface Props {
  id: string;
}

const TokenDetails = ({ id }: Props) => {
  const { state } = useLocation();
  const { account, chainId: walletChainId } = useWallet();

  const provider = useProvider({ chainId: EXPECTED_CHAIN_ID });
  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const lightHouseCoreContractInfo = getCoreLightHouse(chainId);

  const [projectId, setProjectId] = useState(state?.projectId);
  const [isTokenExist, setIsTokenExist] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageEncrypted, setMessageEncrypted] = useState('0x');
  const [indexForSentiments, setIndexForSentiments] = useState(0);
  const [tokenDataForSentiments, setTokenDataForSentiments] = useState<TokenDataForSentiments | undefined>();
  const { data, isLoading } = useProjectExternalById(SENTIMENTS_PROJECT_ID, indexForSentiments);
  const [tokenData, setTokenData] = useState<TokenData>();
  const [ownerOfCard, setOwnerOfCard] = useState('');

  useEffect(() => {
    const getTokenInfo = async () => {
      try {
        const owner = await isTokenIdExist(id, provider, coreContractInfo);
        setOwnerOfCard(owner);
        if (owner) {
          setIsTokenExist(true);
          const tokenId = +id;
          const _tokenData: TokenData = {
            tokenId: tokenId.toString(),
            hash: await getTokenHash(tokenId.toString(), provider, +chainId),
          };
          setTokenData(_tokenData);

          let _projectId = projectId;
          if (!_projectId) {
            _projectId = await getProjectId(tokenId.toString(), provider, chainId);
            setProjectId(_projectId);
          }
          if (_tokenData && _projectId == SENTIMENTS_PROJECT_ID) {
            const index = tokenId - _projectId * 1e6;
            setIndexForSentiments(index);

            if (data) {
              setTokenDataForSentiments({
                tokenId: _tokenData?.tokenId.toString(),
                projectId: _projectId,
                hash: _tokenData?.hash,
                externalAssetDependencies: {
                  [index]: {
                    cid: data['cid'],
                  },
                },
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    getTokenInfo();
  }, [id, data]);

  useEffect(() => {
    const getEncryptedMessage = async () => {
      setMessage('');
      try {
        if (account) {
          const messageEncryptedData = await getUserMessageEncrypted(
            id,
            account,
            provider,
            lightHouseCoreContractInfo
          );
          setMessageEncrypted(messageEncryptedData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getEncryptedMessage();
  }, [account]);

  async function handleClick() {
    if (!account || ownerOfCard != account) {
      toast('You are not owner of this card', {
        type: 'warning',
      });
      return;
    }
    // if(ownerOfCard==account && +walletChainId!=EXPECTED_CHAIN_ID){
    //     toast(`You are connected to wrong network. Please connect to ${provider?.network.name}`,{
    //       type:'error'
    //   })
    //   return
    // }
    try {
      const encryptData = ethers.utils.toUtf8String(messageEncrypted);
      const decryptMessage = await decrypt(encryptData, account);
      setMessage(decryptMessage);
    } catch (error) {}
  }

  if (loading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Box>
        <Alert severity="error">Error when get token detail!</Alert>
      </Box>
    );
  }

  function renderType(projectId: number) {
    switch (projectId.toString()) {
      case ORIGINAL_PROJECT_ID:
        return 'Original';
      case SNOWFLAKE_PROJECT_ID:
        return 'Snowflakes';
      case SENTIMENTS_PROJECT_ID:
        return 'Mosaics';
    }
  }

  function renderSketchArt(projectId: number) {
    switch (projectId.toString()) {
      case ORIGINAL_PROJECT_ID:
        return <SketchArtOriginal parentId="p5-art-block" tokenData={tokenData} text={message} />;
      case SNOWFLAKE_PROJECT_ID:
        return <SketchArtSnowFlake parentId="p5-art-block" tokenData={tokenData} text={message} />;
      case SENTIMENTS_PROJECT_ID:
        if (tokenDataForSentiments)
          return (
            <SketchArtSentiments parentId="p5-art-block" tokenData={tokenDataForSentiments} text={message} />
          );
    }
  }
  return (
    <Box pb={3}>
      {isTokenExist ? (
        <>
          <Box>
            <Grid spacing={2} container>
              <Grid item lg={3}></Grid>
              <Grid item lg={6} md={12}>
                <Box>
                  {!tokenData ? (
                    <Box marginTop={'40%'}>
                      <Loading />
                    </Box>
                  ) : (
                    <div id="p5-art-block" style={{ width: '100%' }}>
                      {renderSketchArt(+projectId)}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item lg={3}>
                <Box marginTop={'22px'} marginLeft={'8px'}>
                  <Typography
                    sx={{ fontFamily: 'Gotham', fontStyle: 'Italic', fontWeight: '400', fontSize: '18px' }}
                  ></Typography>
                  <Box marginLeft={'7px'} sx={{ borderLeft: '1px solid #fff' }} marginTop={'80px'}>
                    <Typography
                      sx={{
                        marginLeft: '6px',
                        fontFamily: 'Inter',
                        lineHeight: '21.6px',
                        fontWeight: '400',
                        fontSize: '18px',
                      }}
                    >
                      {renderType(+projectId)} #{Number(id) - projectId * 1000000}
                      <br></br>
                      Artist: LIGHT HOUSE
                      <br></br>
                      Date: December 23
                    </Typography>
                  </Box>
                  <Box marginLeft={'7px'}>
                    <Box marginTop={'36px'}>
                      {!message &&
                        (account ? (
                          <>
                            <Button
                              sx={{ color: '#FFF', border: '1px solid', padding: '8px 14px' }}
                              onClick={handleClick}
                            >
                              Decrypt message
                            </Button>
                            <Box marginTop="20px">Once decrypted, click on your card to interact</Box>
                          </>
                        ) : (
                          <ConnectProfileButtonCustom />
                        ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* <TransferModal tokenId={id} isOpen={isOpen} onClose={onClose} refetchTokenOwner={checkTokenOwner} /> */}
        </>
      ) : (
        <Box>TokenID does not exist</Box>
      )}
    </Box>
  );
};

export default TokenDetails;
