// @ts-nocheck
import { metamaskProvider } from './common';

export default async function getEncryptPublicKey(account: `0x${string}`) {
  if (typeof metamaskProvider === 'undefined') return '';

  try {
    return await metamaskProvider.request({
      method: 'eth_getEncryptionPublicKey',
      params: [account],
    });
  } catch (error) {
    if (error?.code === -32603) {
      return 'User denied message EncryptionPublicKey.';
    }
    return '';
  }
}
