import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import ConnectButtonCustom from 'components/ConnectButtonCustom';
import Loading from 'components/Loading';
import SketchArtOriginal from 'components/SketchArtOriginal';
import SketchArtSentiments from 'components/SketchArtSentiments';
import SketchArtSnowFlake from 'components/SketchArtSnowFlake';
import { EXPECTED_CHAIN_ID, ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';
import { useUserContext } from 'context/UserContext';
import useWallet from 'hooks/Wallet/useWallet';
import useGiftingMutation from 'hooks/mutation/useGiftingMutation';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TokenData, TokenDataForSentiments } from 'types/common';
import useProjectExternalById from 'utils/contract/useProjectExternalById';
import { getTokenHash } from 'utils/generateTokenData';
import { useProvider } from 'wagmi';
import * as yup from 'yup';

function Receive({ setSnowflakePage, snowflakePage }: { setSnowflakePage: any; snowflakePage: boolean }) {
  const {
    isLoading: isLoadingGift,
    verifyOTPReceiver,
    registerAndReceiveGift,
    getAccessToken,
    getGiftingData,
  } = useGiftingMutation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const passcode = params.get('otp') ?? '';
  const emailReceive = params.get('email') ?? '';
  const userInfo = useUserContext();
  const userWalletAddress = _.get(userInfo, ['walletAddress'], '');
  const isRegister = _.get(userInfo, ['isRegister'], false);
  const refetchUserInfo = _.get(userInfo, ['refetch'], () => {});

  const [tokenDataForSentiments, setTokenDataForSentiments] = useState<TokenDataForSentiments | undefined>();
  const [giftReceiveData, setGiftReceiveData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState<TokenData | undefined>();
  const { account, connected } = useWallet();
  const provider = useProvider({ chainId: EXPECTED_CHAIN_ID });
  const [nftReceived, setNftReceived] = useState(false);
  const [indexForSentiments, setIndexForSentiments] = useState(0);
  const { data, isLoading } = useProjectExternalById(SENTIMENTS_PROJECT_ID, indexForSentiments);

  const schema = (isRegister: boolean) =>
    yup.object({
      otp: yup.string().required('OTP is required.'),
      email: yup
        .string()
        .email('Email is invalid.')
        .when([], {
          is: () => !isRegister,
          then: (schema) => schema.required('Email is required.'),
          otherwise: (schema) => schema,
        }),
    });

  const methods = useForm({
    defaultValues: {
      otp: '',
      email: '',
    },
    resolver: yupResolver(schema(isRegister)),
  });

  const handleClaimNFT = async () => {
    try {
      const accessToken = giftReceiveData.accessToken;

      await registerAndReceiveGift(accessToken);
      await refetchUserInfo();
      toast('Receive NFT Successfully', {
        type: 'success',
      });
      setNftReceived(true);
      methods.reset();
    } catch (err) {
      toast('Can not receive NFT', {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    const getProvider = async () => {
      try {
        setLoading(true);
        const resultGetAccessToken = await getAccessToken({ passcode, emailReceive });
        const accessToken = resultGetAccessToken.data.token;
        const getGiftData = await getGiftingData(accessToken);
        const tokenId = getGiftData.data?.tokenId.toString() as string;
        const getTokenData: TokenData = {
          tokenId,
          hash: await getTokenHash(tokenId, provider, EXPECTED_CHAIN_ID),
        };
        setTokenData(getTokenData);
        setGiftReceiveData({ ...getGiftData.data, accessToken });

        if (getTokenData && getGiftData.data?.projectId == SENTIMENTS_PROJECT_ID) {
          const index = Number(getTokenData?.tokenId) - (getGiftData.data?.projectId ?? 0) * 1e6;
          setIndexForSentiments(index);

          if (data) {
            setTokenDataForSentiments({
              tokenId: getTokenData?.tokenId,
              projectId: getGiftData.data?.projectId,
              hash: getTokenData?.hash,
              externalAssetDependencies: {
                [index]: {
                  cid: data['cid'],
                },
              },
            });
          }
        }
        if (getTokenData && getGiftData.data?.projectId == SNOWFLAKE_PROJECT_ID) {
          setSnowflakePage(true);
        } else {
          setSnowflakePage(false);
        }
      } catch (err) {
        setNftReceived(true);
      } finally {
        setLoading(false);
      }
    };
    getProvider();
  }, [data, setSnowflakePage]);

  function renderType(projectId: number) {
    switch (projectId.toString()) {
      case ORIGINAL_PROJECT_ID:
        return 'Original';
      case SNOWFLAKE_PROJECT_ID:
        return 'Snowflakes';
      case SENTIMENTS_PROJECT_ID:
        return 'Mosaics';
    }
  }

  function renderSketchArt(projectId: number) {
    const parent = 'p5-project-detail';
    const text = giftReceiveData?.decryptedMessage;
    switch (projectId.toString()) {
      case ORIGINAL_PROJECT_ID:
        return <SketchArtOriginal parentId={parent} tokenData={tokenData} text={text} />;
      case SNOWFLAKE_PROJECT_ID:
        return <SketchArtSnowFlake parentId={parent} tokenData={tokenData} text={text} />;
      case SENTIMENTS_PROJECT_ID:
        if (tokenDataForSentiments)
          return <SketchArtSentiments parentId={parent} tokenData={tokenDataForSentiments} text={text} />;
    }
  }
  return (
    <Box>
      <Grid spacing={2} container>
        <Grid item lg={3}></Grid>
        <Grid item lg={6} md={12}>
          <Box>
            {!giftReceiveData ? (
              <Box marginTop={'40%'}>
                {loading ? (
                  <Loading />
                ) : (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Metropolis',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '18px',
                      }}
                    >
                      {' '}
                      This OTP link is invalid or expired or the NFT is already claimed{' '}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <div id="p5-project-detail">
                {renderSketchArt(giftReceiveData && +giftReceiveData.projectId)}
              </div>
            )}
          </Box>
        </Grid>
        <Grid item lg={3}>
          {giftReceiveData ? (
            <Box marginTop={'22px'} marginLeft={'8px'}>
              <Box
                marginLeft={'7px'}
                sx={{
                  borderLeft: snowflakePage
                    ? '2px solid rgba(0, 0, 0, 0.75)'
                    : '2px solid rgba(255, 255, 255, 0.75)',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0',
                }}
                marginTop={'100px'}
              >
                <Typography
                  sx={{
                    padding: '0',
                    justifyContent: 'center',
                    marginLeft: '6px',
                    fontFamily: 'Inter',
                    lineHeight: '21.6px',
                    fontWeight: '400',
                    fontSize: '16px',
                    color: snowflakePage ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255)',
                  }}
                >
                  {renderType(+giftReceiveData.projectId)} #
                  {Number(tokenData?.tokenId) - giftReceiveData.projectId * 1000000}
                  <br></br>
                  Artist: LIGHT HOUSE
                  <br></br>
                  Date: December 2023
                </Typography>
              </Box>
              <Box marginLeft={'7px'}>
                <Typography
                  sx={{
                    marginTop: '40px',
                    fontSize: '14px',
                    fontFamily: 'Metropolis',
                    fontWeight: '400',
                    color: snowflakePage ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255)',
                  }}
                >
                  Click through the card to reveal your message.
                </Typography>
                <Typography
                  sx={{
                    marginTop: '30px',
                    fontSize: '14px',
                    fontFamily: 'Metropolis',
                    fontWeight: '400',
                    color: snowflakePage ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255)',
                  }}
                >
                  You can claim your card as an encrypted NFT below. This requires a MetaMask wallet.
                </Typography>
                {!nftReceived && (
                  <Box marginTop="30px">
                    {!account && !connected ? (
                      <Box
                        sx={{
                          border: snowflakePage
                            ? '2px solid rgba(0, 0, 0, 0.75)'
                            : '2px solid rgba(255, 255, 255, 0.75)',
                          color: snowflakePage ? 'rgba(0, 0, 0)' : 'rgba(255, 255, 255)',
                          fontFamily: 'Metropolis',
                          fontWeight: '400',
                          fontSize: '18px',
                          marginRight: '12px',
                          width: '100%',
                        }}
                      >
                        <ConnectButtonCustom label="CLAIM NFT" snowflakePage={snowflakePage} />
                      </Box>
                    ) : (
                      <LoadingButton
                        sx={{
                          border: snowflakePage
                            ? '2px solid rgba(0, 0, 0, 0.75)'
                            : '2px solid rgba(255, 255, 255, 0.75)',
                          color: snowflakePage ? 'rgba(0, 0, 0)' : 'rgba(255, 255, 255)',
                          fontFamily: 'Metropolis',
                          fontWeight: '400',
                          fontSize: '18px',
                          marginRight: '12px',
                          width: '100%',
                          '& *': {
                            color: snowflakePage ? '#000' : '#fff',
                          },
                        }}
                        loading={isLoadingGift}
                        onClick={handleClaimNFT}
                      >
                        CLAIM NFT
                      </LoadingButton>
                    )}
                  </Box>
                )}
                <Box marginTop={'30px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: '400',
                      fontSize: '14px',
                      color: snowflakePage ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255)',
                      lineHeight: '18px',
                    }}
                  >
                    Note security best practice: use a unique account in MetaMask for Light House cards.
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Box marginTop={'20px'}></Box>
    </Box>
  );
}

export default Receive;
