import Page from 'components/Page';
import Receive from 'components/ReceivePage';
import { useState } from 'react';

function ReceivePage() {
  const [snowflakePage, setSnowflakePage] = useState(false);

  return (
    <Page snowflakePage={snowflakePage}>
      <Receive setSnowflakePage={setSnowflakePage} snowflakePage={snowflakePage} />
    </Page>
  );
}

export default ReceivePage;
