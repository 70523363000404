import { Contract } from 'ethers';
import { ContractInfo } from 'types/common';

export default async function isTokenIdExist(
  tokenId: string,
  signerOrProvider: any,
  coreContractInfo: ContractInfo
) {
  const CoreContract = new Contract(coreContractInfo.address, coreContractInfo.abi, signerOrProvider);

  try {
    const owner = await CoreContract.callStatic.ownerOf(tokenId);

    return owner;
  } catch (err) {
    return '';
  }
}
