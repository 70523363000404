import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Page from 'components/Page';

const AboutFAQ = () => {
  const typoStyle = {
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24,84px',
  };
  return (
    <Page>
       <Box marginBottom={'62px'} marginX={'66px'}>
        <Box>
          <Typography
            sx={{
              color: '#FFF',
              fontFamily: 'Metropolis',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '22.08px',
            }}
          >
            FAQ
          </Typography>
        </Box>
        <Box marginTop={'19px'}>
          <Typography sx={typoStyle}>What are NFTs?</Typography>
          <Typography sx={typoStyle}>
            A non-fungible token is a unique digital identifier that is recorded on a blockchain, and is used
            to certify ownership and authenticity. It cannot be copied, substituted, or subdivided. The
            ownership of an NFT is recorded in the blockchain and can be transferred by the owner, allowing
            NFTs to be sold and traded
          </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            What is generative art?
          </Typography>
          <Typography sx={typoStyle}>
            Generative art is art made using a predetermined system that often includes an element of chance,
            and the term is usually applied to computer based art. 
          </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            Can I pay without using crypto?
          </Typography>
          <Typography sx={typoStyle}>
            You do not have to pay with crypto. You can use a credit or debit card to purchase any Light House
            product.
          </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            Can I buy a card as someone living outside of the United States?
          </Typography>
          <Typography sx={typoStyle}>
            Currently, only people living in the United States with a United States bank card can purchase our
            products. 
          </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            Can I send a card to someone who does not have an NFT wallet?
          </Typography>
          <Typography sx={typoStyle}>
            Yes, when you send someone a card, we will send them an e-mail with a link where they can access
            their card and make a wallet for their NFT in less than five minutes! 
          </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            Do I have to send the card as an NFT?
          </Typography>
          <Typography sx={typoStyle}>No, you do not have to send the card as an NFT. </Typography>
          <Typography sx={typoStyle} marginTop={'40px'}>
            How does Light House support the environment?
          </Typography>
          <Typography sx={typoStyle}>
            We chose direct carbon capture because we believe that, while it is expensive to offset emissions
            today, we know that this is an essential technology to successfully reach net zero by 2050. By
            becoming an earlier adapter of this technology, we can support it in a way which we hope will be
            beneficial to society. We work with a facility in Iceland called Climeworks. If you’re interested,
            you can look at their website here at
            <a href="https://climeworks.com/" target="_blank">
              {' '}
              https://climeworks.com/{' '}
            </a>
            .
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

export default AboutFAQ;
