import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const TitleCardPageStyled = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  fontWeight: 500,
  textAlign: 'center',
  margin: '120px 0',

  [theme.breakpoints.down('md')]: {},

  [theme.breakpoints.down('lg')]: {},
}));
