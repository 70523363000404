import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  containerBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '7px 37px 7px 42px',

    gap: 20,

    '& .text': {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 700,
      fontFamily: 'Metropolis',
    },
  },
});

function Footer() {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();

  return (
    <footer style={{ borderTop: '1px solid #535353', background: '#000' }}>
      <Container maxWidth="xl">
        <Box className={classes.containerBox}>
          <a
            href="https://twitter.com/light_house_io "
            target="_blank"
            rel="noreferrer"
            style={{ width: 'fit-content' }}
          >
            <Typography className={'text'} sx={{ color: 'white' }}>
              Twitter
            </Typography>
          </a>
          <a
            href="https://www.instagram.com/light_house_io/"
            target="_blank"
            rel="noreferrer"
            style={{ width: 'fit-content' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Typography className={'text'} sx={{ color: 'white' }}>
                Instagram
              </Typography>
            </Box>
          </a>
          <Typography className={'text'} sx={{ color: 'white' }}>
            &#169;{currentYear} by LIGHT HOUSE CARDS INC
          </Typography>
          <a
            href={process.env.PUBLIC_URL + '/static/Light-House-Terms-and-Conditions.pdf'}
            style={{ width: 'fit-content' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Typography className={'text'} sx={{ color: 'white' }}>
                TERMS AND CONDITIONS
              </Typography>
            </Box>
          </a>
          <a
            href={process.env.PUBLIC_URL + '/static/Light-House-Privacy-Policy.pdf'}
            style={{ width: 'fit-content' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Typography className={'text'} sx={{ color: 'white' }}>
                PRIVACY POLICY
              </Typography>
            </Box>
          </a>
        </Box>
      </Container>
      <Box sx={{ display: 'flex' }}></Box>
    </footer>
  );
}

export default Footer;
