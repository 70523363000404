// @ts-nocheck
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/Loading';
import { EXPECTED_CHAIN_ID, ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';
import { ethers } from 'ethers';
import useWallet from 'hooks/Wallet/useWallet';
import { useFetchProject } from 'hooks/query/useFetchProject';
import { useCallback, useEffect, useState } from 'react';
import { getProjectAndMinterInfoAt } from 'utils/CoreV3/getProjectAndMinterInfo';
import { getCoreGenArt, getMinterFilterV2 } from 'utils/contract/common';
import { getMintingInterface } from 'utils/getMintingInterface';
import InfoMint from './InfoMint';
import InfoMintProjectId1 from './InfoMintProjectId1';
import InfoMintProjectId2 from './InfoMintProjectId2';
import MintTitleSection from './MintTitleSection';
import MintWithouReceiver from './MintWithouReceiver';

const useStyles = makeStyles({
  tabItem: {
    '&.tab': {
      flex: 1,
      fontFamily: 'Metropolis',
      fontSize: '18px',
      width: '100%',
      height: 58,

      '&:first-child': {
        borderRight: '3px solid',
      },
    },
  },

  tabContainer: {
    border: '3px solid',
  },

  mintSection: {
    flexBasis: '50%',
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

interface MintProps {
  projectId: string;
  snowflakePage?: boolean;
}

function Mint({ projectId, snowflakePage }: MintProps) {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [imageByProjectId, setImageByProjectId] = useState();
  const [v3ProjectAndMinterInfo, setV3ProjectAndMinterInfo] = useState<any | null>(null);

  const imagesSrc = (projectId: string, id: string) => {
    const projectImg = {
      [ORIGINAL_PROJECT_ID]: 'OGS',
      [SNOWFLAKE_PROJECT_ID]: 'SF',
      [SENTIMENTS_PROJECT_ID]: 'MS',
    };
    return `${process.env.PUBLIC_URL}/media/${projectImg[projectId]}${id}.png`;
  };
  const { signer, provider } = useWallet();
  const chainId = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(chainId);
  const mintFilterV2ContractInfo = getMinterFilterV2(chainId);

  const { project, loading: projectLoading } = useFetchProject(projectId);

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleGetPrjectAndMinterInfo = useCallback(
    async (prjId: string) => {
      try {
        const [minterInfo] = await Promise.all([
          getProjectAndMinterInfoAt(prjId, signer, coreContractInfo, mintFilterV2ContractInfo),
        ]);
        setV3ProjectAndMinterInfo(minterInfo);
        setLoading(false);
      } catch (error) {
        console.error({ error });
        setLoading(false);
      }
    },
    [signer]
  );

  const MintingInterface = getMintingInterface('V3', 'MinterSetPriceV5');

  const InfoMintInterface = () => {
    switch (projectId) {
      case ORIGINAL_PROJECT_ID:
        return <InfoMint tab={tab} />;
      case SNOWFLAKE_PROJECT_ID:
        return <InfoMintProjectId1 tab={tab} />;
      case SENTIMENTS_PROJECT_ID:
        return <InfoMintProjectId2 tab={tab} />;
    }
  };

  useEffect(() => {
    setImageByProjectId(imagesSrc[projectId]);
  }, [projectId]);

  return (
    <Box>
      {projectLoading && <Loading />}
      {!projectLoading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MintTitleSection
              image={imageByProjectId}
              projectId={projectId}
              projectName={project.name ?? ''}
              projectDescription={project.description ?? ''}
              snowflakePage={snowflakePage}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '26px',
              justifyContent: 'center',
              marginBottom: '50px',
              marginTop: '16px',
            }}
          >
            <InfoMintInterface />
            <Box className={classes.mintSection}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs
                  className={classes.tabContainer}
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="none"
                  style={{ color: snowflakePage ? 'black' : 'white' }}
                >
                  <Tab
                    className={`${classes.tabItem} tab`}
                    label="GIFT"
                    value={1}
                    style={{
                      color: snowflakePage ? 'black' : 'white',
                      backgroundColor: snowflakePage
                        ? tab === 1
                          ? 'rgba(0,0,0,0.18)'
                          : 'white'
                        : tab === 1
                        ? 'rgba(255,255,255,0.18)'
                        : 'black',
                    }}
                  />
                  <Tab
                    className={`${classes.tabItem} tab`}
                    label="COLLECT"
                    value={0}
                    style={{
                      color: snowflakePage ? 'black' : 'white',
                      backgroundColor: snowflakePage
                        ? tab === 0
                          ? 'rgba(0,0,0,0.18)'
                          : 'white'
                        : tab === 0
                        ? 'rgba(255,255,255,0.18)'
                        : 'black',
                    }}
                  />
                </Tabs>
              </Box>
              <Box sx={{ width: '100%' }}>
                <TabPanel value={tab} index={0}>
                  {MintingInterface != null && (
                    <MintingInterface
                      coreContractAddress={coreContractInfo?.address}
                      projectId={projectId}
                      mintContractAddress={''}
                      artistAddress={''}
                      scriptAspectRatio={0}
                    />
                  )}
                </TabPanel>
              </Box>
              <Box sx={{ width: '100%' }}>
                <TabPanel value={tab} index={1}>
                  <MintWithouReceiver tab={tab} projectId={projectId} />
                </TabPanel>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                marginBottom: '36px',
                fontFamily: 'Metropolis',
                fontSize: '24px',
                fontWeight: '700',
                color: snowflakePage ? 'black' : 'white',
              }}
            >
              VIEW COLLECTION SAMPLES
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '82px',
              }}
            >
              <Box style={{ width: '23%' }}>
                <a href={'../token/' + Number(Number(projectId) * 1e6 + 1)}>
                  <img src={imagesSrc(projectId, 1)} style={{ width: '100%' }} alt="Mint page" />
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: '700',
                      fontSize: '18px',
                      textAlign: 'center',
                      color: snowflakePage ? 'black' : 'white',
                    }}
                  >
                    {project?.name} #1
                  </Typography>
                </a>
              </Box>

              <Box sx={{ width: '23%' }}>
                <a href={'../token/' + Number(Number(projectId) * 1e6 + 2)}>
                  <img src={imagesSrc(projectId, 2)} style={{ width: '100%' }} alt="Mint page" />
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: '700',
                      fontSize: '18px',
                      textAlign: 'center',
                      color: snowflakePage ? 'black' : 'white',
                    }}
                  >
                    {project?.name} #2
                  </Typography>
                </a>
              </Box>
              <Box sx={{ width: '23%' }}>
                <a href={'../token/' + Number(Number(projectId) * 1e6 + 3)}>
                  <img src={imagesSrc(projectId, 3)} style={{ width: '100%' }} alt="Mint page" />
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: '700',
                      fontSize: '18px',
                      textAlign: 'center',
                      color: snowflakePage ? 'black' : 'white',
                    }}
                  >
                    {project?.name} #3
                  </Typography>
                </a>
              </Box>
              <Box sx={{ width: '23%' }}>
                <a href={'../token/' + Number(Number(projectId) * 1e6 + 4)}>
                  <img src={imagesSrc(projectId, 4)} style={{ width: '100%' }} alt="Mint page" />
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: '700',
                      fontSize: '18px',
                      textAlign: 'center',
                      color: snowflakePage ? 'black' : 'white',
                    }}
                  >
                    {project?.name} #4
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Mint;
