import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const MenuBarStyled = styled(Box)(({ theme }) => ({
  display: 'flex',

  marginTop: '5px',
  flex: 1,
  justifyContent: 'flex-end',
  gap: 105,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  [theme.breakpoints.down('lg')]: {
    justifyContent: 'space-around',
    gap: 0,
    paddingRight: '0',
  },
}));
