import useGetWalletData from 'hooks/Wallet/useGetWalletData';
import useWallet from 'hooks/Wallet/useWallet';
import { UserContext } from './UserContext';

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { account } = useWallet();
  const { data, isLoading, refetch } = useGetWalletData(account);
  const isRegister = isLoading || data ? true : false;

  const contextValue = {
    walletAddress: data?.data.walletAddress || account,
    isRegister,
    refetch,
    isLoading,
    encryptPublicKey: data?.data.publicKey,
    email: data?.data.email,
  };
  return <UserContext.Provider value={{ ...contextValue }}>{children}</UserContext.Provider>;
};
