import useWallet from 'hooks/Wallet/useWallet';
import useGiftingApiCall from 'hooks/api/Gifting/useGiftingApiCall';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  GetAccessTokenSchema,
  StripeData,
  VerifyOTPGiferSchema,
  VerifyOTPReceiverSchema,
} from 'types/gifting';
import getEncryptPublicKey from 'utils/contract/getEncryptPulicKey';
import signMessage from 'utils/contract/signMessage';

const useGiftingMutation = () => {
  const {
    collectCall,
    requestOTPCall,
    verifyOTPGifterCall,
    verifyOTPReceiverCall,
    registerAndReceiverCall,
    changeReceiverEmailCall,
    getAccessTokenCall,
    getGiftingDataCall,
    getStripeCall,
  } = useGiftingApiCall();
  const { account, chainId } = useWallet();

  const handleError = (error: any) => {
    if (error.message) {
      toast(`${error.message}`, { type: 'error' });
    } else {
      toast(`${error.data.message}`, { type: 'error' });
    }
    console.log({ error });
  };

  const collect = useMutation(
    async ({
      tokenId,
      txHash,
      fromBlock,
      receiverWalletAddress,
      isAcceptedNotification,
      message,
      priceInUSD,
      zipCode,
    }: any) => {
      if (!account) return;

      return await collectCall({
        chainId: `${+chainId}`,
        tokenId,
        txHash,
        fromBlock,
        receiverWalletAddress,
        message,
        isAcceptedNotification,
        priceInUSD,
        zipCode,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const requestOTP = useMutation(
    async ({
      tokenId,
      txHash,
      fromBlock,
      receiverEmail,
      senderFullName,
      isAcceptedNotification,
      message,
      priceInUSD,
      senderEmail,
      zipCode,
    }: any) => {
      if (!account) return;

      return await requestOTPCall({
        chainId: `${+chainId}`,
        tokenId,
        txHash,
        fromBlock,
        receiverEmail,
        senderFullName,
        isAcceptedNotification,
        message,
        priceInUSD,
        senderEmail,
        zipCode,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const verifyOTPGifter = useMutation(
    async ({ email, otp }: VerifyOTPGiferSchema) => {
      return await verifyOTPGifterCall({
        email,
        otp,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const registerAndReceiveGift = useMutation(
    async (accessToken: string) => {
      if (!account) return;

      const signature = await signMessage(account);
      const encryptPublicKey = await getEncryptPublicKey(account);

      return await registerAndReceiverCall({
        walletAddress: account,
        signature,
        encryptPublicKey,
        accessToken,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        if (error.message) {
          toast(`${error.message}`, { type: 'error' });
        } else {
          toast(`${error.data.message}`, { type: 'error' });
        }
        console.log({ error });
      },
    }
  );

  const verifyOTPReceiver = useMutation(
    async ({ walletAddress, accessToken }: VerifyOTPReceiverSchema) => {
      return await verifyOTPReceiverCall({
        walletAddress,
        accessToken,
      });
    },
    {
      onSuccess(data, variables, context) {
        toast('Receive NFT successfully.', { type: 'success' });
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const changeReceiverEmail = useMutation(
    async ({
      receiverEmail,
      message,
      tokenId,
    }: {
      receiverEmail: string;
      message: string;
      tokenId: string;
    }) => {
      if (!account) return;

      const signature = await signMessage(account);

      return await changeReceiverEmailCall({
        chainId: `${+chainId}`,
        addressGifter: account,
        tokenId,
        signature,
        receiverEmail,
        message,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const getAccessToken = useMutation(
    async ({ passcode, emailReceive }: GetAccessTokenSchema) => {
      return await getAccessTokenCall({
        passcode,
        emailReceive,
      });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const getGiftingData = useMutation(
    async (accessToken: any) => {
      return await getGiftingDataCall(accessToken);
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  const getStripe = useMutation(
    async (data: StripeData) => {
      return await getStripeCall(data);
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        handleError(error);
      },
    }
  );

  return {
    isLoading:
      collect.isLoading ||
      requestOTP.isLoading ||
      verifyOTPGifter.isLoading ||
      verifyOTPReceiver.isLoading ||
      registerAndReceiveGift.isLoading ||
      changeReceiverEmail.isLoading ||
      getAccessToken.isLoading ||
      getGiftingData.isLoading ||
      getStripe.isLoading,
    collect: collect.mutateAsync,
    requestOTP: requestOTP.mutateAsync,
    verifyOTPGifter: verifyOTPGifter.mutateAsync,
    verifyOTPReceiver: verifyOTPReceiver.mutateAsync,
    registerAndReceiveGift: registerAndReceiveGift.mutateAsync,
    changeReceiverEmail: changeReceiverEmail.mutateAsync,
    getAccessToken: getAccessToken.mutateAsync,
    getGiftingData: getGiftingData.mutateAsync,
    getStripeData: getStripe.mutateAsync,
  };
};

export default useGiftingMutation;
