import { gql, useQuery as useClientQuery } from '@apollo/client';

type CardListType = {
  artistName: string;
  id: string;
  projectName: string;
  projectId: string;
  description: string;
  website: string;
};

export const useFetchCardList = () => {
  const GET_CARD_LIST = gql`
    query CardListQuery {
      projects {
        artistName
        description
        id
        license
        updatedAt
        website
        projectId
        name
      }
    }
  `;

  const { data, refetch, loading, error } = useClientQuery(GET_CARD_LIST);
  if (error) {
    return {
      cardList: [],
      refetch: () => {},
      loading: false,
      error,
    };
  }

  const cardList: CardListType[] = data?.projects || [];

  return { cardList, refetch, loading, error };
};
